<template>
  <!-- <v-app> -->

 <!-- <v-app>  -->
 <div >
   
     <v-app > 
 <!-- <head_header v-if="$route.name=='alkafeel'" /> -->
 <sec-header></sec-header>

<div>
    <router-view />
</div>


 <footers />
  </v-app>
 </div>

 <!-- </v-app> -->
</template>



<script>
  export default {
    name: "DashboardIndex",

    components: {
      footers: () => import("../../components/core/foot.vue"),
      secHeader: () => import("./Header.vue"),
    },

    data: () => ({
      expandOnHover: false
    })
  };
</script>


<style >

  
</style>